import React from 'react';
import Routes from './routes';
import './styles/globals.css';

function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
